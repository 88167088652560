let isAdsPortal;

if (process.env.REACT_APP_LOCAL === 'true') {
  isAdsPortal = RegExp('http://(local-ads|local-brands).(superbiz.gg|bloxbiz.com)').test(window.location.href);
} else {
  isAdsPortal = RegExp('https://(ads|brands).(superbiz.gg|bloxbiz.com)').test(window.location.href);
}

const IS_ADS_PORTAL = isAdsPortal;

export default IS_ADS_PORTAL;
