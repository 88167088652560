// react
import { createContext, useContext, ReactNode } from 'react';

// antd
import { notification as AntDNotification, Space, Alert } from 'antd';
import type { NotificationArgsProps } from 'antd';

type Messages = {
  error_messages?: string[];
  warning_messages?: string[];
  info_messages?: string[];
  success_messages?: string[];
};

type NotificationContextType = {
  (message: string, messages: Messages): void;
};

const NotificationContext = createContext<NotificationContextType | null>(null);

type NotificationProviderProps = {
  children: ReactNode;
};

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [api, contextHolder] = AntDNotification.useNotification();

  const notification: NotificationContextType = (message, messages) => {
    const { error_messages = [], warning_messages = [], info_messages = [], success_messages = [] } = messages;

    let type: NotificationArgsProps['type'];

    if (error_messages.length > 0) {
      type = 'error';
    } else if (warning_messages.length > 0) {
      type = 'warning';
    } else if (info_messages.length > 0) {
      type = 'info';
    } else {
      type = 'success';
    }

    api[type]({
      message: message,
      description: (
        <Space direction='vertical'>
          {error_messages.map((error, index) => (
            <Alert key={`error-${index}`} type='error' message={error} />
          ))}
          {warning_messages.map((warning, index) => (
            <Alert key={`warning-${index}`} type='warning' message={warning} />
          ))}
          {info_messages.map((info, index) => (
            <Alert key={`info-${index}`} type='info' message={info} />
          ))}
          {success_messages.map((success, index) => (
            <Alert key={`success-${index}`} type='success' message={success} />
          ))}
        </Space>
      ),
      ...((type === 'error' || type === 'warning') && { duration: 0 }),
    });
  };

  return (
    <NotificationContext.Provider value={notification}>
      {contextHolder}

      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification(): NotificationContextType {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
}
