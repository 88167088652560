let isPublicPortal;

if (process.env.REACT_APP_LOCAL === 'true') {
  isPublicPortal = RegExp('http://local-search.superbiz.gg').test(window.location.href);
} else {
  isPublicPortal = RegExp('https://search.superbiz.gg').test(window.location.href);
}

const IS_PUBLIC_PORTAL = isPublicPortal;

export default IS_PUBLIC_PORTAL;
