// react
import React from 'react';
// bb
import history from 'utils/history';
// antd
import { Result, Button } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('error: ', error);
    console.error('errorInfo', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status='500'
          title='Oops, something went wrong.'
          extra={
            <>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({ hasError: false });
                  history.go(0);
                }}
              >
                Reload Page
              </Button>
              <Button
                type='default'
                onClick={() => {
                  this.setState({ hasError: false });
                  history.push('');
                }}
              >
                Go Home
              </Button>
            </>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
