let isDevPortal;

if (process.env.REACT_APP_LOCAL === 'true') {
  isDevPortal = RegExp('http://local-dev.(superbiz.gg|bloxbiz.com)').test(window.location.href);
} else {
  isDevPortal = RegExp('https://dev.(superbiz.gg|bloxbiz.com)').test(window.location.href);
}

const IS_DEV_PORTAL = isDevPortal;

export default IS_DEV_PORTAL;
